<template>
    <div></div>
</template>

<script>
export default {
  name: 'Redirecter',
  methods: {
    redirect () {
      switch (this.$route.query.to) {
        case 'accountsCreate' : // /redirect?to=accountsCreate&accountType=income
          this.$router.push('/incomes-expenses/accounts/create/' + this.$route.query.accountType)
          break
        case 'accountsAssetsCreate' : // /redirect?to=accountsCreate&accountType=income
          this.$router.push('/assets-liabilities/accounts/create/' + this.$route.query.accountType)
          break
        case 'accountsGroupsCreate' :
          this.$router.push('/incomes-expenses/accounts/groups/create/' + this.$route.query.accountType)
          break
        case 'accountsGroupsCreateAssets' :
          this.$router.push('/assets-liabilities/accounts/groups/create/' + this.$route.query.accountType)
          break
        case 'importRulesCreate' :
          this.$router.push('/transactions/import/rules/create')
          break
        case 'importAccountStatement' :
          this.$router.push('/transactions/import')
          break
        case 'transactionsCreate' :
          this.$router.push('/transactions/create/single')
          break
        case 'ledgerCreate' :
          this.$router.push('/transactions/create/single')
          break
        case 'settingsImport' :
          this.$router.push('/settings/balansbladet-import')
          break
        case 'transactionsUpdateValue' :
          this.$router.push('/assets-liabilities/update-value/' + this.$route.query.accountType + '/' + this.$route.query.id)
          break
        case 'importSpreadsheetFile' :
          this.$router.push('/transactions/import/excel/')
          break
        case 'importSpreadsheetFileAdvanced' :
          this.$router.push('/transactions/import/excel-advanced/')
          break
        case 'createMultiple' :
          this.$router.push('/transactions/create/multiple')
          break
        case 'manageBudget':
          this.$router.push('/budget/account')
          break
        case 'importAccountStatementv2':
          this.$router.push('/transactions/import/account-statement')
          break
        case 'importTink':
          this.$router.push('/transactions/import/tink')
          break
        case 'viewTransactions':
          this.$router.push('/transactions/view')
          break
        case 'importAccountsTink':
          this.$router.push('/assets-liabilities/import-from-tink')
          break
        case 'updateValuesTink':
          this.$router.push('/assets-liabilities/update-values-from-tink')
          break
        case 'createTag':
          this.$router.push({ name: 'transaction-tags-create' })
          break
        case 'balanceFix':
          this.$router.push({ name: 'balance-fix', params: { account_id: this.$route.query.account_id } })
          break
      }
    }
  },
  beforeMount () {
    this.redirect()
  }
}
</script>

<style scoped>

</style>
